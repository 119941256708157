import { USER } from './user'

export const ONBOARDING_STAGES = `
  	id
	questionnaireStep
	organizationStep
  	userProfileStep
  	phoneNumberActivationStep
  	contactsAdded
	is10dlcRegistered
	isOnboardingComplete
	isOptInFlowActive
	isPhoneNumberActive
	teammatesAdded
	textsSent
	isSetupContactAlert
	isSetupOptinAlert
	user{
    ${USER}
  }
`
