import React, {
	memo,
	useEffect,
	useMemo,
	useState,
	Dispatch,
	SetStateAction,
} from 'react'
import SVG from 'react-inlinesvg'
import { useHistory } from 'react-router-dom'
import { translate } from 'i18n'
import { scenes } from 'scenes/scenes'
import { widgetActions } from 'store/slices/widget'
import AppMenuItem, { AppMenuItemProps } from 'modules/App/AppMenuItem'
import { Chat } from 'types/api/chat.model'

import { ReactComponent as AutomationsActiveIcon } from 'assets/icons/menu-automations-active.svg'
import { ReactComponent as AutomationsIcon } from 'assets/icons/menu-automations.svg'
import { ReactComponent as CampaignsActiveIcon } from 'assets/icons/campaigns-flag.svg'
import { ReactComponent as CampaignsIcon } from 'assets/icons/campaigns-flag-outline.svg'
import { ReactComponent as CollapseIcon } from 'assets/icons/collapse_chevrons.svg'
import { ReactComponent as ContactsActiveIcon } from 'assets/icons/contacts-people.svg'
import { ReactComponent as ContactsIcon } from 'assets/icons/contacts-people-outline.svg'
import { ReactComponent as HomeActiveIcon } from 'assets/icons/menu-home-active.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/menu-home.svg'
import { ReactComponent as MessagesActiveIcon } from 'assets/icons/menu-messages-active.svg'
import { ReactComponent as MessagesIcon } from 'assets/icons/menu-messages.svg'
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/menu-settings.svg'
import { ReactComponent as WidgetsIcon } from 'assets/icons/menu-widgets.svg'
import { ReactComponent as PrimaryActiveIcon } from 'assets/icons/messages-primary-active.svg'
import { ReactComponent as PrimaryInActiveIcon } from 'assets/icons/messages-primary-inactive.svg'
import { ReactComponent as CampaignRepliesActiveIcon } from 'assets/icons/messages-campaign-replies-active.svg'
import { ReactComponent as CampaignRepliesInActiveIcon } from 'assets/icons/messages-campaign-replies-inactive.svg'
import { ReactComponent as SpamActiveIcon } from 'assets/icons/spam-active.svg'
import { ReactComponent as SpamInactiveIcon } from 'assets/icons/spam-inactive.svg'
import { ReactComponent as SidebarIcon1 } from 'assets/icons/sidebar-icon-1.svg'
import { ReactComponent as SidebarIcon2 } from 'assets/icons/sidebar-icon-2.svg'
import { ReactComponent as SidebarIcon3 } from 'assets/icons/sidebar-icon-3.svg'
import { ReactComponent as SidebarIcon4 } from 'assets/icons/sidebar-icon-4.svg'
import { ReactComponent as CheckGreenIcon } from 'assets/icons/check-green-icon.svg'
import magicIcon from 'assets/icons/magic.svg'

import {
	AppMenuContainer,
	AppMenuItemsContainer,
	AppMenuSpacer,
	AppMenuActions,
	AppMenuPhoneNumberStatusContainer,
	AppMenuButtonIcon,
	AppMenuButtonIconHover,
	AppMenuOverflowWrapper,
	AppMenuLogoContainer,
	CollapseMenuButtonStyled,
	AppMenuLogoText,
	OnboardingContainer,
	OnboardingBox,
} from './AppMenu.styled'

import { User } from 'types/api/user.model'
import { useAppDispatch, useAppState } from '../../../store'
import { messagesActions } from 'store/slices/messages'
import PhoneNumberStatus from 'components/PhoneNumberStatus'
import { formatUSNumber } from 'utils/format-number'
import CreateMenuButton from './CreateMenuButton'
import SKLogo from 'assets/icons/SKLogo'
import ConvosLogo from 'assets/icons/ConvosLogo'
import { AppHeaderDivider } from '../AppHeader/styled'
import { AppMenuLabel } from '../AppMenuItem/AppMenuItem.styled'
import Spin from 'components/Spin/Spin'

interface IProps {
	currentUser?: User | null
	isCollapsed: boolean
	showBanner: boolean | null
	setIsCollapsed: Dispatch<SetStateAction<boolean>>
}

const AppMenu = memo(
	({ currentUser, isCollapsed, setIsCollapsed, showBanner }: IProps) => {
		const dispatch = useAppDispatch()
		const { user } = useAppState(state => state.auth)
		const { onboardingStages, onboardingLoading } = useAppState(
			state => state.onboarding
		)
		const { inboxConversations } = useAppState(state => state.messages)
		const history = useHistory()
		const [unreadMessage, setUnreadMessage] = useState<boolean>(false)
		const { brand } = useAppState(state => state.brand)
		const { cspCampaign } = useAppState(state => state.cspCampaign)
		const userRole = user?.userRoles?.[0]?.role.id

		const isOrgOwner = userRole === '1'
		const isOrgReg = userRole === '2'
		// const isCspVerified: boolean =
		// 	brand?.identityStatus === 'VERIFIED' &&
		// 	cspCampaign?.status === 'ACTIVE' &&
		// 	cspCampaign.vettingStatus === 'ACCEPTED'
		const isFlowCompleted = isOrgOwner
			? onboardingStages?.is10dlcRegistered &&
			  onboardingStages?.contactsAdded &&
			  onboardingStages.isOptInFlowActive &&
			  onboardingStages.textsSent
			: onboardingStages?.contactsAdded &&
			  onboardingStages.isOptInFlowActive &&
			  onboardingStages.textsSent

		const isVerified = !(isOrgOwner && isFlowCompleted)

		const items = useMemo<AppMenuItemProps[]>(
			() => [
				{
					ActiveIcon: HomeActiveIcon,
					Icon: HomeIcon,
					label: translate('appMenu.homeLabel'),
					href: scenes.dashboard.url,
				},
				{
					ActiveIcon: MessagesActiveIcon,
					Icon: MessagesIcon,
					href: scenes.messages.url({ screen: 'primary' }),
					label: translate('appMenu.messagesLabel'),
					disabled: currentUser
						? currentUser.phoneTransferStatus !== 'phone_transfer_success'
						: true,
					onClick: () => {
						dispatch(messagesActions.setConversationListType('inbox'))
						dispatch(messagesActions.setSelectedConversation({} as Chat))
					},
					children: [
						{
							ActiveIcon: PrimaryActiveIcon,
							Icon: PrimaryInActiveIcon,
							label: translate('appMenu.primaryLabel'),
							href: scenes.messages.url({ screen: 'primary' }),
							disabled: currentUser
								? currentUser.phoneTransferStatus !== 'phone_transfer_success'
								: true,
							onClick: () => {
								dispatch(messagesActions.setConversationListType('inbox'))
								dispatch(messagesActions.setSelectedConversation({} as Chat))
							},
							child: true,
						},
						{
							ActiveIcon: CampaignRepliesActiveIcon,
							Icon: CampaignRepliesInActiveIcon,
							label: translate('appMenu.campaignRepliesLabel'),
							href: scenes.messages.url({ screen: 'engagement' }),
							disabled: currentUser
								? currentUser.phoneTransferStatus !== 'phone_transfer_success'
								: true,
							onClick: () => {
								dispatch(messagesActions.setConversationListType('campaign'))
							},
							child: true,
						},
						{
							ActiveIcon: SpamActiveIcon,
							Icon: SpamInactiveIcon,
							label: translate('appMenu.spam'),
							href: scenes.messages.url({ screen: 'spam' }),
							disabled: currentUser
								? currentUser.phoneTransferStatus !== 'phone_transfer_success'
								: true,
							child: true,
						},
					],
				},
				{
					ActiveIcon: CampaignsActiveIcon,
					Icon: CampaignsIcon,
					label: translate('appMenu.campaignsLabel'),
					href: scenes.campaigns.url,
				},
				{
					ActiveIcon: ContactsActiveIcon,
					Icon: ContactsIcon,
					label: translate('appMenu.contactsLabel'),
					href: scenes.contacts.url(),
					onClick: () => dispatch(messagesActions.clearCurrentConversation()),
				},
				{
					ActiveIcon: AutomationsActiveIcon,
					Icon: AutomationsIcon,
					label: translate('appMenu.automatedMessagesLabel'),
					href: scenes.automatedMessages.url,
				},
				{
					ActiveIcon: WidgetsIcon,
					Icon: WidgetsIcon,
					label: translate('appMenu.widgetsLabel'),
					href: scenes.widgets.url,
				},
				{
					ActiveIcon: SettingsIcon,
					Icon: SettingsIcon,
					label: translate('appMenu.settingsLabel'),
					href: scenes.settings.url,
				},
			],
			[currentUser, dispatch]
		)

		const setUpItems = useMemo<AppMenuItemProps[]>(
			() => [
				{
					ActiveIcon: SidebarIcon1,
					Icon: SidebarIcon1,
					StepCompleteIcon: CheckGreenIcon,
					label: 'Phone # Registration',
					href: scenes.setup.url,
					stepComplete: onboardingStages?.is10dlcRegistered,
				},
				{
					ActiveIcon: SidebarIcon2,
					Icon: SidebarIcon2,
					StepCompleteIcon: CheckGreenIcon,
					label: 'Add & Import Contacts',
					href: scenes.setupCreateContact.url,
					stepComplete: onboardingStages?.contactsAdded,
				},
				{
					ActiveIcon: SidebarIcon3,
					Icon: SidebarIcon3,
					StepCompleteIcon: CheckGreenIcon,
					label: 'Turn on Opt-in Flows',
					href: scenes.setupOptinFlow.url,
					stepComplete: onboardingStages?.isOptInFlowActive,
				},
				{
					ActiveIcon: SidebarIcon4,
					Icon: SidebarIcon4,
					StepCompleteIcon: CheckGreenIcon,
					label: (
						<div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
							Send My First Text <SVG src={magicIcon} />
						</div>
					),
					href: scenes.setupFirstMessage.url,
					stepComplete: onboardingStages?.textsSent,
				},
			],
			[onboardingStages]
		)

		const goToHome = () => {
			history.push(scenes.dashboard.url)
		}

		return (
			<AppMenuContainer collapsed={isCollapsed} showBanner={showBanner}>
				<AppMenuLogoContainer
					onClick={goToHome}
					style={{
						flexDirection: isCollapsed ? 'column' : 'row',
					}}
				>
					<SKLogo
						style={{
							marginRight: isCollapsed ? '0px' : '16px',
							marginBottom: '18px',
						}}
					/>
					<ConvosLogo
						style={{
							marginRight: isCollapsed ? '0px' : '16px',
							marginBottom: '18px',
						}}
					/>
					{!isCollapsed && <AppMenuLogoText>CONVOS</AppMenuLogoText>}
				</AppMenuLogoContainer>
				<AppMenuOverflowWrapper>
					{onboardingLoading ? (
						<Spin style={{ margin: '10px auto', width: '20px' }} />
					) : (
						!isFlowCompleted && (
							<OnboardingBox>
								<OnboardingContainer collapsed={isCollapsed}>
									<AppMenuItemsContainer>
										{!isCollapsed && (
											<AppMenuLabel
												style={{
													marginBottom: '4px',
												}}
											>
												Getting Started
											</AppMenuLabel>
										)}

										{setUpItems.map((item, index) => (
											<React.Fragment key={index}>
												<AppMenuItem
													// unreadMessage={unreadMessage}
													key={item.href}
													{...item}
													disabled={index === 0 && isOrgReg}
													isSetupFlow
												/>
												<React.Fragment>
													{item.children &&
														item.children.map(child => (
															<AppMenuItem
																unreadMessage={unreadMessage}
																key={child.href}
																{...child}
															/>
														))}
												</React.Fragment>
											</React.Fragment>
										))}
									</AppMenuItemsContainer>

									{/* <AppHeaderDivider style={{ marginTop: '10px' }} /> */}
								</OnboardingContainer>
							</OnboardingBox>
						)
					)}

					<AppMenuItemsContainer style={{ marginTop: '8px' }}>
						{items.map((item, index) => (
							<React.Fragment key={index}>
								<AppMenuItem
									unreadMessage={unreadMessage}
									key={item.href}
									{...item}
								/>
								<React.Fragment>
									{item.children &&
										item.children.map(child => (
											<AppMenuItem
												unreadMessage={unreadMessage}
												key={child.href}
												{...child}
											/>
										))}
								</React.Fragment>
							</React.Fragment>
						))}
					</AppMenuItemsContainer>
					<AppMenuSpacer />
					<AppMenuActions>
						<CollapseMenuButton
							hide={!isCollapsed}
							isCollapsed={isCollapsed}
							onClick={() => {
								setIsCollapsed(c => {
									localStorage.setItem('leftSidebarCollapse', String(!c))
									dispatch(widgetActions.updateActionCollapsed(!isCollapsed))
									return !c
								})
							}}
						/>
					</AppMenuActions>
					<AppMenuActions>
						<CreateMenuButton />
						<CollapseMenuButton
							hide={isCollapsed}
							isCollapsed={isCollapsed}
							onClick={() => {
								setIsCollapsed(c => {
									localStorage.setItem('leftSidebarCollapse', String(!c))
									dispatch(widgetActions.updateActionCollapsed(!isCollapsed))

									return !c
								})
							}}
						/>
					</AppMenuActions>
				</AppMenuOverflowWrapper>

				{currentUser && currentUser.organizations?.[0]?.phone && (
					<AppMenuPhoneNumberStatusContainer>
						<PhoneNumberStatus
							status={currentUser.phoneTransferStatus || ''}
							phoneNumber={formatUSNumber(currentUser.organizations[0].phone)}
						/>
					</AppMenuPhoneNumberStatusContainer>
				)}
			</AppMenuContainer>
		)
	}
)

interface CollapseMenuButtonProps {
	hide: boolean
	isCollapsed: boolean
	onClick: () => void
}

const CollapseMenuButton = ({
	hide,
	isCollapsed,
	onClick,
}: CollapseMenuButtonProps) => (
	<CollapseMenuButtonStyled hide={hide} secondary square onClick={onClick}>
		<AppMenuButtonIcon>
			{isCollapsed ? <MenuIcon /> : <CollapseIcon />}
		</AppMenuButtonIcon>
		<AppMenuButtonIconHover reverse={isCollapsed}>
			<CollapseIcon />
		</AppMenuButtonIconHover>
	</CollapseMenuButtonStyled>
)

export default AppMenu
