import React from 'react'
import Icon from '@ant-design/icons'

interface CustomIconComponentProps {
	size: string | number
	width: string | number
	height: string | number
	fill: string
	viewBox?: string
	className?: string
	style?: React.CSSProperties
}

const SKLogoSVG = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_970_72)">
			<path
				d="M11.2822 20.6787C8.3445 22.7627 4.48538 23.5332 0.890625 23.2357C2.94683 24.1602 5.26296 24.699 7.53595 24.6682C11.134 24.6183 11.9923 24.0752 11.2822 20.6787Z"
				fill="#991B1E"
			/>
			<path
				d="M22.4587 21.0099C24.4363 22.7325 28.1273 22.4945 30.5729 22.4573C29.584 23.2342 28.426 23.6401 27.2049 23.9813C26.602 24.1492 23.3048 24.9887 22.8568 24.6635C22.2496 24.2225 22.4177 21.5508 22.4587 21.0088V21.0099Z"
				fill="#991B1E"
			/>
			<path
				d="M0 32.0005C3.40894 31.4245 6.58118 29.5222 8.78891 26.9876C11.8273 23.4986 11.0885 21.0564 8.78117 17.2837C5.28264 11.5641 4.17877 4.42359 11.3716 1.05047C17.548 -1.84655 26.6211 1.44899 26.9441 8.53637C27.1465 12.9765 23.794 16.695 23.9345 20.9831C24.0639 24.9461 28.6707 28.9611 31.9989 30.5966C25.4078 32.3225 19.7092 25.3935 16.8777 20.7546C16.7737 28.6306 6.45951 31.4107 0 32.0026L0 32.0005Z"
				fill="#EF3B39"
			/>
			<path
				d="M7.79102 7.88448C7.79102 7.88448 18.6726 4.79298 31.1625 5.24783C31.1625 5.24783 32.5573 11.9749 30.4878 12.1577C30.4878 12.1577 27.1718 12.9537 23.5881 12.7943C23.5881 12.7943 22.0971 13.2258 22.0539 7.12781L20.0917 7.24896C20.0917 7.24896 21.7121 12.7146 19.7068 13.1588C19.7068 13.1588 14.7936 14.3406 10.4401 14.3406C10.4401 14.3406 8.79644 14.5903 7.79102 7.88554V7.88448Z"
				fill="#1B1D21"
			/>
			<path
				d="M29.9501 7.20452L25.9629 11.2373L26.6044 11.8229L30.5917 7.79008L29.9501 7.20452Z"
				fill="white"
			/>
			<path
				d="M28.06 6.76429L25.332 9.52344L25.9736 10.109L28.7016 7.34984L28.06 6.76429Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_970_72">
				<rect width="32" height="32" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

const SKLogo = ({ size, ...props }: Partial<CustomIconComponentProps>) => (
	<Icon
		component={SKLogoSVG}
		{...props}
		width={size || props.width}
		height={size || props.height}
	/>
)

export default SKLogo
