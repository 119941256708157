import React, {
	memo,
	PropsWithChildren,
	useState,
	useEffect,
	createContext,
	SetStateAction,
	Dispatch,
	useCallback,
} from 'react'
//STORE
import { useAppState, useAppDispatch } from 'store'
import { widgetActions } from 'store/slices/widget'
//COMPONENTS
import AppHeader from 'modules/App/AppHeader'
import AppMenuMobile from 'modules/App/AppMenuMobile/AppMenuMobile'
import AppMenu from 'modules/App/AppMenu'
import AppBannerStatus from '../AppBannerStatus'
//STYLES
import { Container, ContentContainer, Content, AppContent } from './styled'
import { breakpoints } from 'utils/styled'
import useWindowSize from 'hooks/useWindowSize'

export interface collapsed {
	collapsed: boolean
	setCollapsed: Dispatch<SetStateAction<boolean>>
}

export const NavCollapsed = createContext<collapsed>({
	collapsed: false,
	setCollapsed: () => {},
})

export default memo(({ children }: PropsWithChildren<{}>) => {
	const dispatch = useAppDispatch()

	const { user } = useAppState(state => state.auth)
	const [isNavCollapsed, setIsNavCollapsed] = useState(false)

	const { width } = useWindowSize()

	const handleCollapsed = useCallback(
		(collapsed: boolean) => {
			setIsNavCollapsed(collapsed)
			dispatch(widgetActions.updateActionCollapsed(collapsed))
		},
		[dispatch]
	)

	useEffect(() => {
		if (localStorage.getItem('leftSidebarCollapse') === 'true') {
			setIsNavCollapsed(true)
		} else if (width < breakpoints.tabletLarge) {
			setIsNavCollapsed(true)
		} else setIsNavCollapsed(false)
	}, [width])

	useEffect(() => {
		if (!localStorage.getItem('leftSidebarCollapse')) {
			if (width < breakpoints.tabletLarge) {
				handleCollapsed(true)
			} else {
				handleCollapsed(false)
			}
		}
	}, [width, handleCollapsed])

	const showBanner =
		user &&
		((user.organizations?.[0]?.phone && user.phoneTransferStatus === null) ||
			user.phoneTransferStatus !== 'phone_transfer_success')

	return (
		<Container>
			{showBanner && <AppBannerStatus currentUser={user} />}
			<AppContent showBanner={showBanner}>
				<AppMenu
					currentUser={user}
					isCollapsed={isNavCollapsed}
					showBanner={showBanner}
					setIsCollapsed={setIsNavCollapsed}
				/>
				<ContentContainer expanded={isNavCollapsed}>
					<AppHeader currentUser={user} expanded={isNavCollapsed} />
					<NavCollapsed.Provider
						value={{
							collapsed: isNavCollapsed,
							setCollapsed: setIsNavCollapsed,
						}}
					>
						<Content>{children}</Content>
					</NavCollapsed.Provider>
					<AppMenuMobile />
				</ContentContainer>
			</AppContent>
		</Container>
	)
})
