import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	uploadProfileImage,
	approveUser,
	resetPassword,
	updateProfile,
	login,
	getMyTeam,
	getMe,
	getSkContactsCount,
	triggerContactSync,
	addUserCalendarLink,
	forgotPassword,
	orderPhoneNumber,
	getListVoipServices,
	attachDocument,
	assignIonLakeNumber,
	getIonLakeNumber,
	getPhoneNumbers,
	loginWithCookie,
	getAuthByToken,
	updateOrganizationAddress,
	verifyBrainSession,
	getAuthByTokenForOnBoarding,
	toggleEmailNotifications,
	updatePersonalPhone,
} from './auth.actions'
import createAsyncReducers from 'utils/create-async-reducers'
import { LoginResponse, PhoneNumbersQuery } from 'services/auth/auth.types'
import credentialsService from 'services/credentials.service'
import apiService from 'services/api'
import { User } from '../../../types/api/user.model'
import { Voip } from 'types/api/voip.model'
import { Organization } from 'types/api/organization.model'

export interface IState {
	auth: LoginResponse | null
	authorized: boolean
	loading: boolean
	skContactsCount: number
	contactSyncUserId: string | null
	loadingListVoipServices: boolean
	user: User | null
	myTeam?: User[]
	phoneNumbers: PhoneNumbersQuery[]
	listVoipServices: Voip[]
	isExceeded: boolean
	ionLakeNumber?: { phone: string; id: string }
}

const initialState: IState = {
	auth: null,
	user: null,
	authorized: false,
	skContactsCount: 0,
	loading: false,
	contactSyncUserId: null,
	loadingListVoipServices: false,
	isExceeded: false,
	phoneNumbers: [],
	listVoipServices: [],
}

const { reducer, actions } = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginFromCookie(state, action: PayloadAction<string>) {
			state.authorized = true
			credentialsService.saveAuthToken(action.payload)
			apiService.createClient()
		},
		setIsExceeded(state, action: PayloadAction<boolean>) {
			state.isExceeded = action.payload
		},
		setIonLakeNumber(
			state,
			action: PayloadAction<{ phone: string; id: string } | undefined>
		) {
			state.ionLakeNumber = action.payload
		},
		// This is a bit of a quick fix but don't want to fetch data again from backend when subscription is canceled
		setSubscriptionCancelationDate(state, action: PayloadAction<Date>) {
			if (
				state.user &&
				state.user.organizations &&
				state.user.organizations[0]
			) {
				state.user.organizations[0].subscriptionCancelDate = action.payload
			}
		},
		setOptinFlow(state, action: PayloadAction<boolean>) {
			if (
				state.user &&
				state.user.organizations &&
				state.user.organizations[0]
			) {
				state.user.organizations[0].optinFlowEnabled = action.payload
			}
		},
		updateUserFirstOrganization(state, action: PayloadAction<Organization>) {
			if (
				state.user &&
				state.user.organizations &&
				state.user.organizations[0]
			) {
				state.user.organizations[0] = action.payload
			}
		},
		updateUser(state, action: PayloadAction<User>) {
			state.user = action.payload
		},
		updateUserOrganization: (state, action: PayloadAction<Organization>) => {
			const user = state.user as User
			const updatedUser = { ...user, organizations: [action.payload] }
			state.user = updatedUser
		},
	},
	extraReducers: builder => {
		createAsyncReducers(builder, login, 'loading', (state, action) => {
			state.authorized = true
			state.auth = action.payload
			credentialsService.saveAuthToken(action.payload.token)
			apiService.createClient()
		})
		createAsyncReducers(
			builder,
			loginWithCookie,
			'loading',
			(state, action) => {
				state.authorized = true
				state.auth = action.payload
				credentialsService.saveAuthToken(action.payload.token)
				apiService.createClient()
			}
		)
		createAsyncReducers(builder, approveUser, 'loading')
		createAsyncReducers(builder, resetPassword, 'loading', (state, action) => {
			state.authorized = true
			if (action.payload && action.payload.token) {
				state.auth = { id: action.payload.id, token: action.payload.token }
				credentialsService.saveAuthToken(action.payload.token)
			}
			state.user = action.payload
			apiService.createClient()
		})
		createAsyncReducers(builder, getAuthByToken, 'loading', (state, action) => {
			state.authorized = true
			if (action.payload && action.payload.token) {
				state.auth = { id: action.payload.id, token: action.payload.token }
				credentialsService.saveAuthToken(action.payload.token)
			}
			state.user = action.payload
			apiService.createClient()
		})
		createAsyncReducers(
			builder,
			getAuthByTokenForOnBoarding,
			'loading',
			(state, action) => {
				if (action.payload && action.payload.token) {
					state.auth = { id: action.payload.id, token: action.payload.token }
					credentialsService.saveAuthToken(action.payload.token)
				}
				state.user = action.payload
				apiService.createClient()
			}
		)
		createAsyncReducers(builder, getMe, 'loading', (state, action) => {
			state.user = action.payload
		})
		createAsyncReducers(
			builder,
			getSkContactsCount,
			'loading',
			(state, action) => {
				state.skContactsCount = action.payload
			}
		)
		createAsyncReducers(
			builder,
			triggerContactSync,
			'loading',
			(state, action) => {
				state.contactSyncUserId = action.payload
			}
		)
		createAsyncReducers(builder, updateProfile, 'loading', (state, action) => {
			state.user = action.payload
		})
		createAsyncReducers(
			builder,
			uploadProfileImage,
			'loading',
			(state, action) => {
				state.user = action.payload.user
			}
		)
		createAsyncReducers(builder, getMyTeam, 'loading', (state, action) => {
			state.myTeam = action.payload
		})
		createAsyncReducers(builder, addUserCalendarLink, 'loading')
		createAsyncReducers(builder, forgotPassword, 'loading')
		createAsyncReducers(
			builder,
			getPhoneNumbers,
			'loading',
			(state, action) => {
				state.phoneNumbers = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getIonLakeNumber,
			'loading',
			(state, action) => {
				state.ionLakeNumber = {
					id: '',
					phone: action.payload.phoneNumber,
				}
			}
		)
		createAsyncReducers(
			builder,
			getListVoipServices,
			'loadingListVoipServices',
			(state, action) => {
				state.listVoipServices = action.payload
			}
		)
		createAsyncReducers(
			builder,
			orderPhoneNumber,
			'loading',
			(state, action) => {
				const updatedUser = action.payload?.users?.find(
					user => user?.id === state?.user?.id
				)
				if (updatedUser) {
					state.user = { ...updatedUser, organizations: [action.payload] }
				}
			}
		)
		createAsyncReducers(
			builder,
			updateOrganizationAddress,
			'loading',
			(state, action) => {
				const updatedUser = action.payload?.users?.find(
					user => user?.id === state?.user?.id
				)
				if (updatedUser) {
					state.user = { ...updatedUser, organizations: [action.payload] }
				}
			}
		)
		createAsyncReducers(builder, attachDocument, 'loading')
		createAsyncReducers(
			builder,
			assignIonLakeNumber,
			'loading',
			(state, action) => {
				state.ionLakeNumber = action.payload
			}
		)
		createAsyncReducers(builder, verifyBrainSession, 'loading')
		createAsyncReducers(
			builder,
			toggleEmailNotifications,
			'loading',
			(state, action) => {
				state.user = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updatePersonalPhone,
			'loading',
			(state, action) => {
				state.user = action.payload
			}
		)
	},
})

export default reducer
export const authActions = {
	...actions,
	uploadProfileImage,
	approveUser,
	resetPassword,
	getMyTeam,
	updateProfile,
	login,
	loginWithCookie,
	getMe,
	getSkContactsCount,
	addUserCalendarLink,
	forgotPassword,
	getPhoneNumbers,
	orderPhoneNumber,
	getListVoipServices,
	attachDocument,
	assignIonLakeNumber,
	triggerContactSync,
	getIonLakeNumber,
	getAuthByToken,
	getAuthByTokenForOnBoarding,
	updateOrganizationAddress,
	verifyBrainSession,
	toggleEmailNotifications,
	updatePersonalPhone,
}
